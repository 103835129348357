import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteDataFromApi,
  postDataFromApi,
  putDataFromApi,
} from "../../services/commonServices";
import {
  getFormatedDate,
  isModuelePermission,
} from "../../services/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "./DeleteConfirmation";
import {
  InternalCertificateDeleteApi,
  JRFDeleteApi,
  JRFPDFDownloadApi,
  SFMDeleteApi,
  allotmentDeleteApi,
  sampleInwardDetailsDeleteAPI,
  sampleverificationDeleteApi,
  testMemoDeleteApi,
  folderCreateApi,
  InternalCertificateGetPDFApi,
  masterUploadApi,
  documentCreateApi
} from "../../services/api";
import { historyData } from "../../actions/authActions";
import PropTypes from "prop-types";
import { encryptDataForURL } from "../../utills/useCryptoUtils";
import { useTranslation } from "react-i18next";

const PopupOptions = ({
  popupActions,
  setPopupIndex,
  id,
  row,
  section,
  getAllListingData,
  formConfig,
  type,
  sampleInwardFormId,
  model,
  isBottom,
  status,
  setDontClick,
  setIsOverlayLoader
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
    const translate = t;
  let user;
  const session = useSelector((state) => state.session);
  user = session?.user;

  let rolePermissions;
  rolePermissions = session?.user?.permissions;

  const [actions, setActions] = useState(popupActions);
  const [isDelete, setIsDelete] = useState(false);
  const [actionType, setActionType] = useState(false);


  const handleClick = async (value, actionType = "") => {
    setActionType(value);
    setDontClick(true);

    if (formConfig?.listView?.moduleType === "sampleinward") {
      if (actionType === "View") {
        navigate(
          `/inwardList/inwardForm?status=${encryptDataForURL(value)}&id=${encryptDataForURL(id)}&sampleInwardId=${encryptDataForURL(sampleInwardFormId)}&action=${encryptDataForURL('View')}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Inward No " + row.smpl_inward_number,
          model: model,
          redirect: "/inwardList",
          Breadcrumb: "Sample Inward List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(row["smpl_inwrd_id"])}`);


        setPopupIndex(-1);
      } else if (value === "assignmentview") {
        navigate(
          "/inwardList/groupAssignmentPreview?status=" + encryptDataForURL('assignment') + "&sampleInwardId=" +
          encryptDataForURL(sampleInwardFormId) +
          "&id=" +
          encryptDataForURL(id)
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(
          `/inwardList/inwardForm?status=${encryptDataForURL(value)}&id=${encryptDataForURL(id)}&sampleInwardId=${encryptDataForURL(sampleInwardFormId)}&action=${encryptDataForURL('Edit')}`
        );
      }
    } else if (formConfig?.listView?.moduleType === "testmemomain") {
      if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "View") {
        navigate(
          `/testmemoList/testmemo?view=${encryptDataForURL('view')}&status=${encryptDataForURL('testMemo')}&testMemoId=${encryptDataForURL(row["tm_id"])}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Test Memo No " + row.tm_number,
          model: model,
          redirect: "/testmemoList",
          Breadcrumb: "Test Memo List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(row["tm_id"])}`);

        setPopupIndex(-1);
      } else if (value === "PDF") {
        const historyDetails = {
          recordNo: "Test Memo No " + row.tm_number,
          model: model,
          redirect: "/testmemoList",
          Breadcrumb: "Test Memo List",
        };
        dispatch(historyData(historyDetails));
        const encryptedValue = encryptDataForURL(value);
        const encryptedId = encryptDataForURL(row["tm_id"]);
        navigate(`/testmemoList/testmemoPDF?status=${encryptedValue}&id=${encryptedId}`);

        setPopupIndex(-1);
      }
      else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "allotment") {
      if (value === "View") {
        navigate(
          `/allotmentList/allotment?view=${encryptDataForURL('view')}&status=${encryptDataForURL('allotment')}&sampleAllotmentId=${encryptDataForURL(row["sa_id"])}`
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Allotment No " + row.tm_number,
          model: model,
          redirect: "/allotmentList",
          Breadcrumb: "Allotment List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(row["sa_id"])}`);

        setPopupIndex(-1);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "sampleverification") {
      if (value === "View") {
        navigate(
          `/verificationList/sampleVerification?view=${encryptDataForURL('view')}&status=${encryptDataForURL('verification')}&sampleVarificationId=${encryptDataForURL(row["sv_id"])}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Verification No " + row.sv_verificationno,
          model: model,
          redirect: "/verificationList",
          Breadcrumb: "Verification List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(row["sv_id"])}`);

        setPopupIndex(-1);
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "sfm") {
      if (value === "View") {
        navigate(
          `/SFMList/result?view=${encryptDataForURL('view')}&status=${encryptDataForURL('SFMResult')}&sfmid=${encryptDataForURL(row["sfm_id"])}`
        );
      } else if (value === "PDF") {
        const historyDetails = {
          recordNo: "SFM No " + row.sfm_msfm_no,
          model: model,
          redirect: "/SFMList",
          Breadcrumb: "SFM List",
        };
        dispatch(historyData(historyDetails));
        const encryptedValue = encryptDataForURL(value);
        const encryptedId = encryptDataForURL(row["sfm_id"]);
        navigate(`/SFMList/SFMPDF?status=${encryptedValue}&id=${encryptedId}`);

        setPopupIndex(-1);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "SFM No " + row.sfm_msfm_no,
          model: model,
          redirect: "/SFMList",
          Breadcrumb: "SFM List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(row["sfm_id"])}`);

        setPopupIndex(-1);
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "internalcertificate") {
      if (value === "View") {
        navigate(
          `/testmemoList/test-results?view=${encryptDataForURL('view')}&status=${encryptDataForURL('testmemoresult')}&editId=${encryptDataForURL(row["ic_id"])}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "ULR No " + row.ic_ulrno,
          model: model,
          redirect: "/testReport",
          Breadcrumb: "Test Report List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(row["ic_id"])}`);

        setPopupIndex(-1);
      } else if (value === "Edit") {
        navigate(
          `/testmemoList/test-results?status=${encryptDataForURL('testmemoresult')}&editId=${encryptDataForURL(row["ic_id"])}`
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "Preview Report") {
        navigate(`/testReport/preview/${encryptDataForURL(row["ic_id"])}`);
        // handleTestReport(row);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "jrf") {

      if (value === "Delete") {
        setIsDelete(true);

      } else if (value === "Download") {
        navigate(`/jrfListing/jrf-pdf-preview/${encryptDataForURL(id)}`);
        setPopupIndex(-1);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "JRF No " + row.jrf_no,
          model: model,
          redirect: "/jrfListing",
          Breadcrumb: "JRF List",
        };

        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${encryptDataForURL(value)}&id=${encryptDataForURL(id)}`);

        setPopupIndex(-1);
      } else {
        if (row.jrf_is_external) {
          navigate(`/jrfListing/external-jrf?status=${encryptDataForURL(value)}&id=${encryptDataForURL(id)}`);
        }
        else {
          navigate(`/jrfListing/jrf?status=${encryptDataForURL(value)}&id=${encryptDataForURL(id)}`);
        }
        setPopupIndex(-1);
      }
    }
  };
  const handleTestReport = async (row) => {
    console.log("row", row.ic_id);
    let folderPayload = {
      data: {
        fd_name: row.ic_id,
      },
      parent_folder: "commercial_certificate",
    };
    let folderRes = await postDataFromApi(folderCreateApi, folderPayload);
    let FolderID;
    setIsOverlayLoader(true);

    if (folderRes.data.status === 201 || folderRes.data.status === 200) {
      FolderID = folderRes?.data?.data.fd_id;
    } else {
      FolderID = folderRes?.data?.message?.existing_folder_id;
    }
    if (
      FolderID &&
      (folderRes?.data.status === 200 || folderRes?.data?.status === 400)
    ) {
      let payload, generateCertificateResponse;

      payload = {
        ic_id: row.ic_id
      };
      generateCertificateResponse = await postDataFromApi(
        InternalCertificateGetPDFApi,
        payload,
        "",
        true,
        "",
        ""
      );

      if (
        generateCertificateResponse &&
        generateCertificateResponse.data &&
        generateCertificateResponse.data.status === 200
      ) {
        const pdfBlob = new Blob([generateCertificateResponse.data], {
          type: "application/pdf",
        });
        let payload = new FormData();
        payload.append("document", pdfBlob, "Test Report_" + row?.jrf_referenceno + ".pdf");
        payload.append("model_type ", "commercial_certificate");
        payload.append("bypass_file_size_check ", true);
        payload.append("sub_folder", 6);
        let uploadResponse = await postDataFromApi(
          masterUploadApi,
          payload,
          "TRUE"
        );

        if (uploadResponse.data.status === 200) {
          let payload = {
            data: {
              dl_folder: FolderID,
              dl_sub_folder: 6,
              dl_module: "commercial_certificate",
              dl_document_name: row.ic_id,
              dl_document_reference: row?.fk_jiid,
              dl_type: "Document Type",
              dl_show_to_all: true,
              dl_s3_url: uploadResponse.data?.data?.document,
              dl_version: "1.0",
              dl_file_type: "Test Report",
              dl_date_uploaded: "2023-10-23T12:00:00Z",
              dl_status: "Active",
              dl_assigned_to: "Assigned User",
              dl_discription: row.ic_id,
            },
          };
          let documentResponse = await postDataFromApi(
            documentCreateApi,
            payload
          );
          if (documentResponse.data.status === 200) {
            navigate(
              "/testReport/previewPDF/" +
              encryptDataForURL(documentResponse.data.data.dl_id)
            );
          }
          else {
            setIsOverlayLoader(false);
          }


        } else {
          toast.error(
            uploadResponse?.message ||
            uploadResponse?.data?.message ||
            translate("loginPage.unExpectedError"),
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setIsOverlayLoader(false);
        }
      } else {
        toast.error(
          generateCertificateResponse?.message ||
          generateCertificateResponse?.data?.message ||
          translate("loginPage.unExpectedError"),
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setIsOverlayLoader(false);
      }
    } else {
      setIsOverlayLoader(false);
    }
  };

  const handleDeleteData = async () => {
    if (formConfig?.listView?.moduleType === "sampleinward") {
      handleSimpleInwardDeleteData();
      return;
    } else if (formConfig?.listView?.moduleType === "testmemomain") {
      handleTestMemoDeleteData();
      return;
    } else if (formConfig?.listView?.moduleType === "allotment") {
      handleAllotmentDelete();
      return;
    } else if (formConfig?.listView?.moduleType === "sampleverification") {
      handleSampleVerificationDelete();
      return;
    } else if (formConfig?.listView?.moduleType === "sfm") {
      handleSFMDelete();
      return;
    } else if (formConfig?.listView?.moduleType === "internalcertificate") {
      handleinternalcertificateDelete();
      return;
    } else {
      let deleteBody = {
        jrf_id: id,
      };
      let res = await deleteDataFromApi(JRFDeleteApi, deleteBody);
      if (res?.data?.status === 200) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsDelete(false);
      setPopupIndex(-1);
      getAllListingData();
    }
  };
  const handleSimpleInwardDeleteData = async () => {
    let res;
    if (row["status"] == "rejected") {
      let MainData = {
        jrf_id: id,
        jrf_data: {
          jrf_status: "cancelled",
        },
      };
      res = await putDataFromApi(formConfig.apiEndpoints.update, MainData);
    } else {
      let deleteBody = {
        smpl_inwrd_id: sampleInwardFormId,
      };
      res = await deleteDataFromApi(sampleInwardDetailsDeleteAPI, deleteBody);
    }
    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleTestMemoDeleteData = async () => {
    let deleteBody = {
      test_memo_id: row.tm_id,
    };
    let res = await deleteDataFromApi(testMemoDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleAllotmentDelete = async () => {
    let deleteBody = {
      sa_id: row.sa_id,
    };
    let res = await deleteDataFromApi(allotmentDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleSampleVerificationDelete = async () => {
    let deleteBody = {
      sv_id: row.sv_id,
    };
    let res = await deleteDataFromApi(sampleverificationDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleSFMDelete = async () => {
    let deleteBody = {
      sfm_id: row.sfm_id,
    };
    let res = await deleteDataFromApi(SFMDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleinternalcertificateDelete = async () => {
    let deleteBody = {
      ic_id: row.ic_id,
    };
    let res = await deleteDataFromApi(InternalCertificateDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleDownloadJRF = async () => {
    let bodyData = {
      jrf_id: id,
    };
    let res = await postDataFromApi(JRFPDFDownloadApi, bodyData, "", 1);
    if (res?.status === 200) {
      let pdfDate = "JRF" + id + "_" + getFormatedDate(new Date(), "", 1);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = pdfDate + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setIsDelete(false);
    setPopupIndex(-1);
  };
  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";
    if (actionType == "Delete") {
      headingMsg = formConfig?.popupMessages?.delete?.headerMsg;
      titleMsg = formConfig?.popupMessages?.delete?.titleMsg;
    } else if (actionType == "Download") {
      headingMsg = formConfig?.popupMessages?.download?.headerMsg;
      titleMsg = formConfig?.popupMessages?.download?.titleMsg;
    }
    return (
      <DeleteConfirmation
        isOpen={isDelete}
        handleClose={setIsDelete}
        handleConfirm={() =>
          actionType == "Download" ? handleDownloadJRF() : handleDeleteData()
        }
        popupMessage={titleMsg}
        popupHeading={headingMsg}
      />
    );
  };
  const chkActionVisibility = (
    rolePermissions,
    module,
    permission,
    value = ""
  ) => {
    let isVisbile = false;
    if (
      permission &&
      isModuelePermission(rolePermissions, module, permission)
    ) {
      if (module == "jrf") {
        if (value.toLowerCase() === "edit") {
          let data = {
            BU: ["saved", "rejected"],
            LR: ["saved"],
          };
          if (data[user?.role].includes(row.jrf_status)) {
            isVisbile = true;
          }
        } else if (value.toLowerCase() === "delete") {
          let data = {
            BU: ["saved", "rejected"],
            LR: [],
          };
          if (data[user?.role].includes(row.jrf_status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "sampleinward") {
        if (value.toLowerCase() === "edit") {
          let data = {
            LR: ["created", "saved"],
          };
          if (data[user?.role].includes(row.smpl_status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "internalcertificate") {
        if (value.toLowerCase() === "edit") {
          let data = {
            LR: ["rejected", "saved"],
            TM: [],
            STM: [],
            QM: [],
            SQM: [],
            SLC: [],
            DTM: [],
            LC: [],
          };
          if (data[user?.role].includes(row.status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      }
      else {
        isVisbile = true;
      }
    } else {
      if (value.toLowerCase() === "download") {
        let data = {
          BU: ["posted", "saved"],
          LR: ["posted", "saved"],
        };
        if (!data[user?.role].includes(row.jrf_status)) {
          isVisbile = true;
        }
      }
      else if (value.toLowerCase() === "pdf" && (formConfig?.listView?.moduleType === "sfm" || formConfig?.listView?.moduleType === "testmemomain")) {
        let data = {
          TM: ["certified"],
          STM: ["certified"],
          QM: ["certified"],
          SQM: ["certified"],
          SLC: ["certified", "completed"],
          LR: ["certified"],
          DTM: ["certified"],
          LC: ["completed"]
        }
        if (user?.all_roles?.main_role_id && user?.all_roles?.other_roles?.length) {
          if (formConfig?.listView?.moduleType === "testmemomain") {
            data[user?.role]?.push('verified')
          }

        }
        if (data[user?.role].includes(row.status || row.sfm_status)) {
          isVisbile = true;
        }
      }
      else if (value.toLowerCase() === "history") {
        isVisbile = true;
      }

    }
    return isVisbile;
  };
  return (
    <div className={"popupOptions" + (isBottom ? " IsBottom" : "")}>
      {actions.map((action, actionIndex) => {
        return action.value &&
          action.value !== "" &&
          action.type === "icon" &&
          action.status !== "assignmentview" &&
          chkActionVisibility(
            rolePermissions,
            section.moduleType,
            action.permission,
            action.value
          ) ? (
          <div key={"sample-inward-" + actionIndex}>
            <button
              className="nonNativeButton2"
              aria-label="Sample Inward Action Button"
              onClick={() =>
                formConfig?.listView?.moduleType === "sampleinward"
                  ? handleClick(action.status, action.value)
                  : handleClick(action.value)
              }
            >
              <i className={action.label}></i>
              {action.value}
            </button>
          </div>
        ) : action.status === "assignmentview" &&
          row["smpl_status"] === "assigned" ? (
          <div key={"actionIndex-" + actionIndex}>
            <button
              className="nonNativeButton2"
              aria-label="Assignment View Button"
              onClick={() =>
                formConfig?.listView?.moduleType === "sampleinward"
                  ? handleClick(action.status, action.value)
                  : handleClick(action.value)
              }
            >
              <i className={action.label}></i>
              {action.value}
            </button>
          </div>
        ) : null;
      })}
      {isDelete && openDeletePopup()}
    </div>
  );
};
PopupOptions.propTypes = {
  popupActions: PropTypes.arrayOf(PropTypes.object),
  setPopupIndex: PropTypes.func,
  id: PropTypes.string,
  row: PropTypes.object,
  section: PropTypes.string,
  getAllListingData: PropTypes.func,
  formConfig: PropTypes.object,
  type: PropTypes.string,
  sampleInwardFormId: PropTypes.string,
  model: PropTypes.object,
  isBottom: PropTypes.bool,
  status: PropTypes.string,
  setDontClick: PropTypes.func,
};

PopupOptions.propTypes = {
  popupActions: PropTypes.array.isRequired,
  setPopupIndex: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  getAllListingData: PropTypes.func.isRequired,
  formConfig: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  sampleInwardFormId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};

export default PopupOptions;
