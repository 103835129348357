import React from "react";
import { useNavigate } from "react-router-dom";
import { cretateTestMemoDetails } from "./commonHandlerFunction/testMemoFunctionHandler";
import { encryptDataForURL } from "../../utills/useCryptoUtils";
import { GetTenantDetails, postDataFromApi } from "../../services/commonServices";
import { folderCreateApi, InternalCertificateGetPDFApi, masterUploadApi, documentCreateApi } from "../../services/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const ListingActionButton = ({
  status,
  actions,
  user,
  moduleType,
  jrf_id,
  sampleInwardFormId,
  row,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  setIsOverlayLoader
}) => {
  const { t } = useTranslation();
      const translate = t;
  const navigate = useNavigate();
  const actionBtnList = {
    jrf: [
      {
        btnStatus: "posted",
        label: "Verify Checklist",
        status: "checklist",
        btnshortName: "verifyCheckList",
      },
      {
        btnStatus: "accepted",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "completed",
        label: "Test Report",
        status: "testReport",
        btnshortName: "testReport",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "Edit",
      },
    ],
    sampleinward: [
      {
        btnStatus: "inwarded",
        label: "Assignment",
        status: "assignment",
        btnshortName: "assignment",
      },
      {
        btnStatus: "assigning",
        label: "Assignment",
        status: "assignment",
        btnshortName: "assignment",
      },
      {
        btnStatus: "created",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "saved",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "assigned",
        label: "Create Test Memo",
        status: "testMemo",
        btnshortName: "testMemo",
      },
      {
        btnStatus: "certified",
        label: "Test Report",
        status: "certified",
        btnshortName: "testReport",
      },
    ],
    allotment: [
      {
        btnStatus: "pending",
        label: "Allot",
        status: "allot",
        btnshortName: "allot",
      },
      {
        btnStatus: "allotted",
        label: "Update",
        status: "allotted",
        btnshortName: "Edit",
      },
      {
        btnStatus: "allotted",
        label: "Verification",
        status: "verification",
        btnshortName: "verification",
      },
    ],
    testmemomain: [
      {
        btnStatus: "pending",
        label: "Verify Test Memo",
        status: "pending",
        btnshortName: "verifytestmemo",
      },
      {
        btnStatus: "posted",
        label: "Allot",
        status: "posted",
        btnshortName: "create-allot",
      },
      {
        btnStatus: "created",
        label: "Send to Lab",
        status: "sendToLab",
        btnshortName: "sendToLab",
      },
      {
        btnStatus: "results",
        label: "Verify Test Result",
        status: "results",
        btnshortName: "verifytestresult",
      },
      {
        btnStatus: "verified",
        label: "Test Report",
        status: "verified",
        btnshortName: "testmemoresult",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "Edit",
      },
    ],
    sampleverification: [
      {
        btnStatus: "pending",
        label: "Verification",
        status: "verification",
        btnshortName: "verification",
      },
    ],
    sfm: [
      {
        btnStatus: "pending",
        label: "Result",
        status: "SFMResult",
        btnshortName: "SFMResult",
      },
      {
        btnStatus: "in-process",
        label: "Result",
        status: "SFMResult",
        btnshortName: "SFMResult",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "SFMUpdate",
        btnshortName: "SFMUpdate",
      },
    ],
    internalcertificate: [
      {
        btnStatus: "dtm-approved",
        label: "Verify",
        status: "verifyByTm",
        btnshortName: "verifyByTm",
      },
      {
        btnStatus: "pending",
        label: "Verify",
        status: "verifyByDTM",
        btnshortName: "verifyByDTM",
      },
      {
        btnStatus: "tm-approved",
        label: "Publish",
        status: "publishBtn",
        btnshortName: "publishBtn",
      },
      {
        btnStatus: "dtm-reject",
        label: "Update",
        status: "dtmReject",
        btnshortName: "dtmReject",
      },
      {
        btnStatus: "tm-reject",
        label: "Update",
        status: "tmReject",
        btnshortName: "tmReject",
      },
    ],
  };

  const rolesWiseBtnList = {
    jrf: {
      BU: ["testReport", "Edit"],
      LR: ["testReport", "verifyCheckList", "sampleInward", "Edit"],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: [],
      LC: [],
      DTM: [],
    },
    sampleinward: {
      BU: ["testReport"],
      LR: ["assignment", "testMemo", "testReport", "sendToLab", "sampleInward"],
      TM: ["verifytestmemo"],
      STM: ["verifytestmemo"],
      QM: ["verifytestmemo"],
      SQM: ["verifytestmemo"],
      SLC: ["verifytestmemo"],
      LC: [],
      DTM: [],
    },
    allotment: {
      BU: ["testReport"],
      LR: ["assignment", "testMemo", "certified"],
      TM: ["verifytestmemo", "Edit"],
      STM: ["verifytestmemo", "Edit"],
      QM: ["verifytestmemo", "Edit"],
      SQM: ["verifytestmemo", "Edit"],
      SLC: ["verifytestmemo", "verification"],
      LC: ["verification"],
      DTM: ["verifytestmemo", "verification"],
    },
    sampleverification: {
      BU: [],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: ["verification"],
      LC: ["verification"],
      DTM: ["verification"],
    },
    testmemomain: {
      BU: [],
      LR: ["sendToLab", "testmemoresult", "Edit"],
      TM: ["verifytestmemo", "create-allot", "verifytestresult"],
      STM: ["verifytestmemo", "create-allot", "verifytestresult"],
      QM: ["verifytestmemo", "create-allot", "verifytestresult"],
      SQM: ["verifytestmemo", "create-allot", "verifytestresult"],
      SLC: ["verifytestmemo", "verifytestresult"],
      LC: ["verification"],
      DTM: ["create-allot"],
    },
    sfm: {
      BU: [],
      LR: [],
      TM: [],
      STM: [],
      QM: [],
      SQM: [],
      SLC: ["SFMResult", "SFMUpdate"],
      LC: ["SFMResult", "SFMUpdate"],
      DTM: ["SFMResult", "SFMUpdate"],
    },
    internalcertificate: {
      BU: [],
      LR: ["publishBtn", "dtmReject", "tmReject"],
      TM: ["verifyByTm", "verifyByDTM"],
      STM: ["verifyByTm"],
      QM: ["verifyByTm"],
      SQM: ["verifyByTm"],
      SLC: ["verifyByDTM"],
      LC: [],
      DTM: ["verifyByDTM"],
    },
  };

  const getBtnListArray = () => {
    let btnFilteredData = [];
    btnFilteredData = actionBtnList[moduleType].filter((btn) => {
      if (GetTenantDetails(1, 1) === "TPBPL" && moduleType === "allotment" && ['LC', 'SLC', 'DTM'].includes(user?.role)) {
        return false;
      }
      return rolesWiseBtnList[moduleType]?.[user?.role].includes(
        btn.btnshortName
      );
    });
    return btnFilteredData;
  };
  //table Action buttion handler
  const handleOnclick = (action) => {
    if (moduleType === "sampleinward") {
      if (action.status === "testMemo") {
        cretateTestMemoDetails(sampleInwardFormId, action, navigate, setIsOverlayLoader);
      } else if (action?.status == "testReport") {
        navigate(action?.redirectUrl + "/" + encryptDataForURL(row["internal_certificate_id"]));
        // handleTestReport(row, action);
      } else {
        navigate(
          action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sampleInwardId=" +
          encryptDataForURL(sampleInwardFormId) +
          "&id=" +
          encryptDataForURL(jrf_id)
        );
      }
    } else if (moduleType === "allotment") {
      navigate(
        action?.redirectUrl +
        "?status=" +
        encryptDataForURL(action?.status) +
        "&sampleAllotmentId=" +
        encryptDataForURL(row["sa_id"])
      );
    } else if (moduleType === "sampleverification") {
      navigate(
        action?.redirectUrl +
        "?status=" +
        encryptDataForURL(action?.status) +
        "&sampleVarificationId=" +
        encryptDataForURL(row["sv_id"])
      );
    } else if (moduleType === "testmemomain") {
      if (
        action.status === "create-allot" ||
        action.status === "sendToLab" ||
        action.status === "verifytestmemo"
      ) {
        navigate(
          action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&testMemoId=" +
          encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "verifytestresult") {
        navigate(
          action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&testMemoId=" +
          encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "testmemoresult") {
        navigate(
          action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&testMemoId=" +
          encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "Edit") {
        navigate(
          action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&testMemoId=" +
          encryptDataForURL(row["tm_id"])
        );
      }
    } else if (moduleType === "sfm") {
      navigate(
        action?.redirectUrl +
        "?status=" +
        encryptDataForURL(action?.status) +
        "&sfmid=" +
        encryptDataForURL(row["sfm_id"])
      );
    } else if (moduleType === "internalcertificate") {
      localStorage.setItem("icId", encryptDataForURL(row["ic_id"]));
      if (action?.status == "approveByDTM") {
        setIsPopupOpen(true);
        setJRFCreationType("approve");
      } else if (action?.status == "rejectByDTM") {
        setIsRejectPopupOpen(true);
      } else {
        navigate(
          action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&editId=" +
          encryptDataForURL(row["ic_id"])
        );
      }
    } else {
      if (action?.status == "testReport") {
        navigate(action?.redirectUrl + "/" + encryptDataForURL(row["internal_certificate_id"]));
        // handleTestReport(row, action);
      } else {
        if (user?.role === "LR" && status === "rejected" && row['jrf_is_external']) {
          navigate(
            "/jrfListing/external-jrf?status=" + encryptDataForURL(action?.status) + "&id=" + encryptDataForURL(jrf_id)
          );
        }
        else {
          navigate(
            action?.redirectUrl + "?status=" + encryptDataForURL(action?.status) + "&id=" + encryptDataForURL(jrf_id)
          );
        }
      }
    }
  };
  const handleTestReport = async (row, action) => {
    console.log("row", row.internal_certificate_id);
    let folderPayload = {
      data: {
        fd_name: row.internal_certificate_id,
      },
      parent_folder: "commercial_certificate",
    };
    let folderRes = await postDataFromApi(folderCreateApi, folderPayload);
    let FolderID;
    setIsOverlayLoader(true);

    if (folderRes.data.status === 201 || folderRes.data.status === 200) {
      FolderID = folderRes?.data?.data.fd_id;
    } else {
      FolderID = folderRes?.data?.message?.existing_folder_id;
    }
    if (
      FolderID &&
      (folderRes?.data.status === 200 || folderRes?.data?.status === 400)
    ) {
      let payload, generateCertificateResponse;

      payload = {
        ic_id: row["internal_certificate_id"]
      };
      generateCertificateResponse = await postDataFromApi(
        InternalCertificateGetPDFApi,
        payload,
        "",
        true,
        "",
        ""
      );

      if (
        generateCertificateResponse &&
        generateCertificateResponse.data &&
        generateCertificateResponse.data.status === 200
      ) {
        const pdfBlob = new Blob([generateCertificateResponse.data], {
          type: "application/pdf",
        });
        let payload = new FormData();
        payload.append("document", pdfBlob, "Test Report_" + row?.jrf_referenceno + ".pdf");
        payload.append("model_type ", "commercial_certificate");
        payload.append("bypass_file_size_check ", true);
        payload.append("sub_folder", 6);
        let uploadResponse = await postDataFromApi(
          masterUploadApi,
          payload,
          "TRUE"
        );

        if (uploadResponse.data.status === 200) {
          let payload = {
            data: {
              dl_folder: FolderID,
              dl_sub_folder: 6,
              dl_module: "commercial_certificate",
              dl_document_name: row.internal_certificate_id,
              dl_document_reference: row?.fk_jiid,
              dl_type: "Document Type",
              dl_show_to_all: true,
              dl_s3_url: uploadResponse.data?.data?.document,
              dl_version: "1.0",
              dl_file_type: "Test Report",
              dl_date_uploaded: "2023-10-23T12:00:00Z",
              dl_status: "Active",
              dl_assigned_to: "Assigned User",
              dl_discription: row.internal_certificate_id,
            },
          };
          let documentResponse = await postDataFromApi(
            documentCreateApi,
            payload
          );
          if (documentResponse.data.status === 200) {
            console.log("documentResponse : ", documentResponse.data.data.dl_id);
            console.log("documentResponse : ", documentResponse.data.data.dl_s3_url);
            navigate(
              action?.redirectUrl +
              "/" +
              encryptDataForURL(documentResponse.data.data.dl_id)
            );
          }
          else {
            setIsOverlayLoader(false);
          }


        } else {
          toast.error(
            uploadResponse?.message ||
            uploadResponse?.data?.message ||
            translate("loginPage.unExpectedError"),
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setIsOverlayLoader(false);
        }
      } else {
        toast.error(
          generateCertificateResponse?.message ||
          generateCertificateResponse?.data?.message ||
          translate("loginPage.unExpectedError"),
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setIsOverlayLoader(false);
      }
    } else {
      setIsOverlayLoader(false);
    }
  };

  const checkRoleBseCondition = (buttonDetails) => {
    if (moduleType === "jrf") {
      if (user?.role === "LR" && status === "rejected") {
        if(!row['jrf_is_external'] && !row['jrf_is_regular']){
          return false;
        }
      }
    }
    else if (moduleType === "allotment") {
      if (user?.role === "DTM") {
        if (user?.logged_in_user_info?.usr_id !== row['sa_sampleallottedto']) {
          return false;
        }
      }
      else if (user?.role === "LC") {
        if (GetTenantDetails(1, 1) === "TPBPL") {
          return false;
        }
      }
    }
    if (user?.all_roles?.main_role_id && user?.all_roles?.other_roles?.length) {
      if (['testmemoresult', 'testReport'].includes(buttonDetails.btnshortName)) {
        return false;
      }
    }
    return true;
  }
  const chkBtnExists = (action) => {
    const validConditions = getBtnListArray();
    const filterData = validConditions.filter((condition) => {
      if (!checkRoleBseCondition(condition)) {
        return false;
      }
      if (action.isDupplicate) {
        return (
          condition.btnStatus === status && condition.btnshortName === action?.status
        );
      }
      else {
        return (
          condition.btnStatus === status && condition.label === action?.label
        );
      }
    });
    return Boolean(filterData.length > 0);
  };
  return actions.map((action, actionIndex) => {
    const isValid = status && chkBtnExists(action);

    if (!isValid || action?.type === "icon") {
      return null;
    }
    return (
      <button
        type="button"
        key={"listing-action" + actionIndex}
        className="iconBtn"
        onClick={() => handleOnclick(action)}
      >
        {action?.label}
      </button>
    );
  });
};

export default ListingActionButton;
